export default function ArrowLeft(props: { size?: number }) {
  const size = props.size ?? 16;

  return (
    <svg
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2L5.14427 7.67692C5.09873 7.71836 5.06243 7.76841 5.03761 7.82397C5.0128 7.87954 5 7.93945 5 8C5 8.06055 5.0128 8.12046 5.03761 8.17603C5.06243 8.23159 5.09873 8.28164 5.14427 8.32308L11 14"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
