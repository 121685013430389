import * as echarts from "echarts/core";
import ReactECharts from "echarts-for-react/lib/core";

import { Pin, Report } from "../../reports/Report";
import { useCallback, useEffect, useRef, useState } from "react";
import { exportPdf } from "@impulso/common/common/pdfExport";
import { ECElementEvent, EChartsOption, EChartsType } from "echarts";
import { FormatSettings } from "./BarChartReport";
import UpChevronIcon from "@impulso/common/Icons/UpChevronIcon";
import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon";
import Download from "@impulso/common/Icons/Download";
import SearchlocateMirror from "@impulso/common/Icons/SearchLocateMirror";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { OnClickOutside } from "@impulso/common/common/utilities/OnClickoutside";

export interface BarData {
  label: string;
  underLabel?: string;
  tooltipLabel?: (() => HTMLLIElement[]) | HTMLLIElement[];
  value: number;
}

interface ChartReportProps<T extends BarData> {
  onViewMoreClicked?: () => void;

  options: EChartsOption;
  title: string;
  dateSpan: string;

  onBarClick?(e: T): void;

  format?: FormatSettings;
  height?: string;
  isMobile?: boolean;
  rightAction?: JSX.Element[] | JSX.Element;
  exportDirection?: "portrait" | "landscape";
  exportExcel?: () => Promise<void>;

  pin?: Pin;

  customStyling?: string;
}

export function ChartReport<T extends BarData>(props: ChartReportProps<T>) {
  const reportElement = useRef<HTMLDivElement>(null);
  const [exportPdfLoading, setExportPdfLoading] = useState(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [echartsInstance, setEchartsInstance] = useState<EChartsType | undefined>(undefined);

  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  OnClickOutside(
    wrapperRef,
    useCallback(() => {
      setOpen(false);
    }, []),
  );
  const hoverStyle = "hover:bg-gray-100 hover:cursor-pointer";

  function formatTitleAsId(title: string) {
    return title.toLowerCase().replaceAll(" ", "_");
  }

  const onBarClick = props.onBarClick;
  const onClick = useCallback(
    (e: ECElementEvent) => {
      onBarClick && onBarClick(e.data as T);
    },
    [onBarClick],
  );

  useEffect(() => {
    if (echartsInstance !== undefined) {
      echartsInstance.on("click", onClick);
      return () => {
        echartsInstance.off("click", onClick);
      };
    }
  }, [echartsInstance, onClick]);

  const doPdfExport = useCallback(async () => {
    try {
      setExportPdfLoading(true);
      await exportPdf(
        reportElement.current,
        props.title,
        props.dateSpan,
        "chart",
        props.exportDirection ?? "portrait",
        echartsInstance,
      );
    } catch (e) {
      console.error(e);
    } finally {
      setExportPdfLoading(false);
    }
  }, [reportElement, echartsInstance, props.title, props.dateSpan, props.exportDirection]);

  const exportExcel = props.exportExcel;
  const doExcelExport = useCallback(async () => {
    try {
      if (exportExcel !== undefined) {
        setExportExcelLoading(true);
        await exportExcel();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setExportExcelLoading(false);
    }
  }, [exportExcel]);

  const noContent = props.title !== "";
  return (
    <Report
      id={formatTitleAsId(props.title)}
      containerRef={reportElement}
      title={props.title}
      dateSpan={props.dateSpan}
      isMobile={props.isMobile}
      height={props.height}
      customStyling={props.customStyling}
      pin={props.pin}
    >
      <ReactECharts
        ref={el => setEchartsInstance(el?.getEchartsInstance())}
        style={{ height: "100%", width: "100%" }}
        echarts={echarts}
        option={props.options}
      />
      <div className="absolute bottom-0 right-0 flex">
        {props.onViewMoreClicked && (
          <span className="my-4 mr-2 mobile:mx-6 flex">
            <div className="relative text-xs" ref={wrapperRef}>
              <SecondaryButton
                onClick={props.onViewMoreClicked}
                label="View More"
                rightIcon={<SearchlocateMirror />}
                textSize={"text-xs"}
                extraStyle="w-full"
              />
            </div>
          </span>
        )}

        {noContent && (
          <span ref={wrapperRef} className="my-4 mr-4 mobile:mx-6 flex gap-4 relative">
            <SecondaryButton
              loading={exportPdfLoading || exportExcelLoading}
              label="Export"
              rightIcon={open ? <UpChevronIcon /> : <DownChevronIcon />}
              textSize={"text-xs"}
              extraStyle="w-full"
              onClick={() => {
                setOpen(!open);
              }}
            />
            <div
              className={`absolute top-[34px] right-0  ${open ? "" : "scale-y-0 opacity-0"} text-xs origin-top bg-white drop-shadow-md z-20 transition-all`}
            >
              <div
                className={`py-2 px-4 flex gap-2 ${hoverStyle}`}
                onClick={() => {
                  setOpen(false);
                  doExcelExport();
                }}
              >
                <p className="truncate">{"Breakdown (.xlsx)"}</p>
                <Download />
              </div>
              <div
                className={`py-2 px-4 flex gap-2 ${hoverStyle}`}
                onClick={() => {
                  setOpen(false);
                  doPdfExport();
                }}
              >
                <p className="truncate">{"Export (.pdf)"}</p>
                <Download />
              </div>
            </div>
          </span>
        )}
      </div>
      {props.rightAction && <span className="m-4 mobile:mx-6 absolute left-0 bottom-0">{props.rightAction}</span>}
    </Report>
  );
}
