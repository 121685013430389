import { Skeleton } from "@mantine/core";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { IndividualStock, useLazyGetIndividualStockHistoryQuery } from "src/api/ReportApi";
import { useEffect, useMemo, useRef, useState } from "react";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { SimpleBadge } from "@impulso/common/components/Badge";
import { OrganisationId } from "src/api/models/UserProfile";
import { Language } from "@impulso/common/Language";
import FormatDate from "@impulso/common/styling/FormatDate";
import { DashFilter } from "src/pages/new/Dashboard";
import { clamp } from "src/common/Utils";
import LineChartReport from "src/common/components/reports/lineChartReport";
import { colors } from "@impulso/common/Theme";
import { StockLevelPerDayInfo } from "src/api/Models";
import { format, parse, startOfDay } from "date-fns";
import { Report } from "../../common/reports/Report";
import { ExportButton } from "@impulso/common/components/buttons/exportButton";
import { exportPdf } from "@impulso/common/common/pdfExport";

interface IndividualStocksProps {
  individualStocks: IndividualStock[];
  isLoading: boolean;
  organisationId: OrganisationId;
  filter: DashFilter;
  exportIsLoading?: boolean;
  onRequestExport: () => void;
}

export function IndividualStockOverview(props: IndividualStocksProps) {
  const totalWholesale = props.individualStocks
    .map(i => i.wholesalePrice * i.stock)
    .reduce((acc, curr) => acc + curr, 0);
  const totalStock = props.individualStocks.map(i => i.stock).reduce((acc, curr) => acc + curr, 0) ?? 0;

  const totalDelivered = props.individualStocks
    .map(i => i.itemsReceived)
    .reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0);
  const totalSold = props.individualStocks.map(i => i.itemsSold).reduce((acc, curr) => (acc ?? 0) + (curr ?? 0), 0);
  const totalSellthrough = clamp(totalDelivered === 0 ? 0 : (totalSold ?? 0) / totalDelivered, 0, 1) * 100;

  const currency = props.individualStocks[0]?.currency ?? "SEK";

  return (
    <div className="grid desktop:grid-cols-4 mt-6 grid-cols-2 v-tablet:grid-cols-1 gap-[24px]">
      <OverviewItem
        title="Total Stock Wholesale"
        amount={Language.FormatMoney(totalWholesale)}
        unit={currency}
        visible={(props.individualStocks.length ?? 0) > 0}
        loading={props.isLoading}
      />
      <OverviewItem
        title="Total Stock"
        amount={totalStock.toString()}
        unit="PCS"
        visible={(props.individualStocks.length ?? 0) > 0}
        loading={props.isLoading}
      />
      <OverviewItem
        title="Sell-through"
        amount={totalSellthrough.toFixed(0).toString()}
        unit="%"
        visible={(props.individualStocks.length ?? 0) > 0}
        loading={props.isLoading}
      />
    </div>
  );
}

function OverviewItem(props: { title: string; amount: string; unit: string; visible?: boolean; loading?: boolean }) {
  if (!props.visible) return <></>;

  return (
    <div className="flex flex-col border-t border-gray-300 pt-2 gap-[7px] col-span-1 mb-10">
      <p className="font-sans font-normal text-sc text-gray-600">{props.title.toUpperCase()}</p>
      <Skeleton visible={props.loading} radius={0}>
        <p className="font-serif text-4xl">
          {props.amount}
          <span className="ml-2 font-sans text-l">{props.unit}</span>
        </p>
      </Skeleton>
    </div>
  );
}

export default function IndividualStocksReport(props: IndividualStocksProps) {
  const columnData = useMemo(() => getColumnData(), []);
  function getIdAccessor(IndividualStock: IndividualStock): string {
    return Object.values(IndividualStock).join(",");
  }

  return (
    <div>
      <TableReport
        columns={columnData}
        rows={props.individualStocks}
        rowBody={individualStock => (
          <ArticleBody individualStock={individualStock} organisationId={props.organisationId}></ArticleBody>
        )}
        pageSize={25}
        defaultSort={{ accessor: "styleName", direction: "asc" }}
        idAccessor={getIdAccessor}
        isLoading={props.isLoading}
        loadingText="Loading stock..."
        noContentMessage={"No articles found"}
        exportLink={{
          isExporting: props.exportIsLoading ?? false,
          label: "Export stock balance (.xls)",
          onExport: () => props.onRequestExport(),
        }}
      />
    </div>
  );
}

function getColumnData(): TableReportColumn<IndividualStock>[] {
  const styleName: TableReportColumn<IndividualStock> = {
    accessor: "styleName",
    sortable: true,
    title: "Style Name",
    visibility: "alwaysVisible",
    definedWidth: "!w-[15%] h-tablet:!w-[30%]",
  };
  const store: TableReportColumn<IndividualStock> = {
    accessor: "inventoryName",
    sortable: true,
    title: "Location",
    visibility: "visible",
    definedWidth: "!w-[15%]",
  };
  const brand: TableReportColumn<IndividualStock> = {
    accessor: "brand",
    sortable: true,
    title: "Brand",
    visibility: "visible",
    render: is => <GetBrand key="brand" brand={is.brand} />,
    definedWidth: "!w-[20%]",
  };
  const size: TableReportColumn<IndividualStock> = {
    accessor: "size",
    sortable: true,
    title: "Size",
    visibility: "alwaysVisible",
    definedWidth: "!w-[10%] h-tablet:!w-[25%]",
  };
  const color: TableReportColumn<IndividualStock> = {
    accessor: "color",
    sortable: true,
    title: "Color",
    visibility: "alwaysVisible",
    definedWidth: "!w-[15%] h-tablet:!w-[25%]",
  };
  const quantity: TableReportColumn<IndividualStock> = {
    accessor: "stock",
    sortable: true,
    title: "Stock",
    visibility: "alwaysVisible",
    definedWidth: "!w-[10%] h-tablet:!w-[20%]",
  };
  const sellThrough: TableReportColumn<IndividualStock> = {
    accessor: "sellThrough",
    sortable: true,
    title: "Sell Through",
    visibility: "visible",
    render: is => <p key="sellThrough"> {is.sellThrough?.toString() ?? 0} % </p>,
    definedWidth: "!w-[10%] h-tablet:!w-[20%]",
  };

  return [styleName, store, brand, size, color, quantity, sellThrough];
}

function GetBrand(props: { brand: string }) {
  return (
    <div className="relative w-[20%] min-w-[120px]">
      <div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
        <SimpleBadge className="h-6">{props.brand.toUpperCase()}</SimpleBadge>
      </div>
    </div>
  );
}

function ArticleBody(props: { individualStock: IndividualStock; organisationId: OrganisationId }) {
  const sellThrough = props.individualStock.sellThrough ?? 0;
  return (
    <div>
      <div className="relative pt-4 pb-12 grid grid-cols-4 w-full gap-y-6 v-tablet:grid-cols-2 v-tablet:gap-y-4">
        {props.individualStock.imageUrl && (
          <div className="mr-4 border-r relative v-tablet:border-r-0">
            <div
              style={{ backgroundImage: `url("${props.individualStock.imageUrl}")` }}
              className="absolute inset-0 right-4 bg-contain bg-no-repeat"
            ></div>
          </div>
        )}
        <ColContainer rightBorder={props.individualStock.imageUrl !== undefined}>
          <BodyItem label="Ean" value={props.individualStock.ean} />
          <BodyItem label="Style number" value={props.individualStock.styleNumber} />
          <BodyItem label="Season" value={props.individualStock.seasonName} />
        </ColContainer>
        <ColContainer leftPadding={props.individualStock.imageUrl !== undefined} rightBorder>
          <BodyItem label="Sell through" value={`${clamp(sellThrough, 0, 100).toFixed(0)}%`} />
          <BodyItem
            label="Wholesale"
            value={Language.FormatMoney(props.individualStock.wholesalePrice) + " " + props.individualStock.currency}
          />
          <BodyItem label="Category" value={props.individualStock.category ?? "No Category"} />
        </ColContainer>
        <ColContainer leftPadding>
          <BodyItem
            label="Last Sale"
            value={props.individualStock.lastSale === null ? "No Sale" : FormatDate(props.individualStock.lastSale)}
          />
          <BodyItem
            label="Last Delivery"
            value={
              props.individualStock.lastDelivery === null
                ? "No Delivery"
                : FormatDate(props.individualStock.lastDelivery)
            }
          />
          <BodyItem
            label="Imported"
            value={
              Date.parse(props.individualStock.imported) <= Date.parse("2024-03-04")
                ? "Before " + FormatDate("2024-03-04")
                : FormatDate(props.individualStock.imported)
            }
          />
        </ColContainer>
        {props.individualStock.lastUpdated ? (
          <p className="absolute left-0 bottom-2 text-sc text-gray-900 uppercase">
            Last Updated: {FormatDate(props.individualStock.lastUpdated)}
          </p>
        ) : (
          <p className="absolute left-0 bottom-2 text-sc text-gray-900 uppercase">Last Updated: Never</p>
        )}
      </div>
      <ArticleStockGraph
        organisationId={props.organisationId}
        ean={props.individualStock.ean}
        inventoryId={props.individualStock.inventoryId}
        inventoryName={props.individualStock.inventoryName}
      />
    </div>
  );
}

interface ArticleStockGraphProps {
  organisationId: OrganisationId;
  ean: string;
  inventoryId: string;
  inventoryName: string;
}

function ArticleStockGraph(props: ArticleStockGraphProps) {
  const [getStockHistoryTrigger, lazyStockHistory] = useLazyGetIndividualStockHistoryQuery();
  const [stockData, setStockData] = useState<StockLevelPerDayInfo[]>([]);
  const reportElement = useRef<HTMLDivElement>(null);
  const [exportLoading, setExportLoading] = useState(false);

  const colorSet = [colors.brand[600]];
  const lineNames = [props.ean];

  useEffect(() => {
    let isLoading = true;
    getStockHistory();

    return () => {
      isLoading = false;
    };

    async function getStockHistory() {
      const args = {
        organisationId: props.organisationId,
        ean: props.ean,
        inventoryId: props.inventoryId,
      };

      const result = await getStockHistoryTrigger(args, true);

      if (result.data === undefined || !isLoading) {
        return;
      }

      setStockData(result.data);
    }
  }, [props.organisationId, props.ean, props.inventoryId]);

  const lineData: [Date, number][][] | undefined = useMemo(() => {
    return [
      stockData.map(d => {
        const val: [Date, number] = [startOfDay(parse(d.day.split("T")[0], "yyyy-MM-dd", Date.now())), d.stockLevel];
        return val;
      }),
    ];
  }, [stockData]);

  const doExport = async () => {
    try {
      setExportLoading(true);
      await exportPdf(
        reportElement.current,
        "Stock History " + props.ean + " " + props.inventoryName,
        dateSpan,
        "chart",
        "portrait",
      );
    } catch (e) {
      console.error(e);
    } finally {
      setExportLoading(false);
    }
  };

  if (stockData === undefined || stockData.length === 0) return <></>;

  const dateSpan =
    format(lineData[0][0][0], "dd MMM yyyy") + " - " + format(lineData[0][lineData[0].length - 1][0], "dd MMM yyyy");
  const currentStock = stockData[stockData.length - 1].stockLevel;

  return (
    <div className="mt-4 mb-6">
      <Report containerRef={reportElement} id="individual-stock-history" title={"Stock History"} dateSpan={dateSpan}>
        <div className="flex m-2 ml-4 max-w-fit">
          <div className="flex text-black">
            <p className="text-6xl font-serif">{currentStock}</p>
            <p className="ml-2 mb-2 text-l uppercase self-end">PCS</p>
          </div>
        </div>
        <LineChartReport
          data={[...lineData]}
          lineNames={lineNames}
          graphType="pcs"
          colorSet={colorSet}
          isLoading={lazyStockHistory.isFetching}
        />
        <div className="absolute bottom-0 left-0 right-0 gap-4 text-xs justify-self-end flex mx-4 mb-4 text-black">
          <ExportButton loading={exportLoading} label={"Export (.pdf)"} type="button" onClick={() => doExport()} />
        </div>
      </Report>
    </div>
  );
}

function ColContainer(props: {
  children: JSX.Element | JSX.Element[] | never[];
  rightBorder?: boolean;
  leftPadding?: boolean;
}) {
  const border = props.rightBorder ? " border-r row-span-1 border-gray-300 v-tablet:!border-r-0" : "";
  const padding = props.leftPadding ? " ml-4 v-tablet:ml-0" : "";
  return <div className={"flex grow grid grid-rows-3 gap-6" + border + padding}>{props.children}</div>;
}

function BodyItem(props: { label: string; value: string }) {
  return (
    <div className="flex flex-col gap-1 v-tablet:col-span-1">
      <p className="text-sc text-gray-600 uppercase">{props.label}</p>
      <p className="text-M text-gray-900 font-semibold">{props.value}</p>
    </div>
  );
}
