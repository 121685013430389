import { EChartsOption, LegendComponentOption, LineSeriesOption } from "echarts";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

import { colors } from "@impulso/common/Theme";
import ReactECharts from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { useMemo, useRef } from "react";
import { TooltipStyling } from "./ReportStyling";
import { Dictionary } from "echarts/types/src/util/types";
import { DatasetComponent, GridComponent, TooltipComponent } from "echarts/components";
import { Loader } from "@mantine/core";
import { format } from "date-fns/format";

echarts.use([LineChart, CanvasRenderer, GridComponent, TooltipComponent, DatasetComponent]);

interface MoneyFormat {
  type: "money";
  currency: string;

  /** Default true */
  compressThousands?: boolean;
}

interface PostfixFormat {
  type: "postfix";
  postfix: string;
}

export type GraphData = [Date, number, string?][];

export type SelectedLines = { [key: string]: boolean };

type FormatSettings = MoneyFormat | PostfixFormat;

export type LineChartReportProps = {
  data: GraphData[];
  totalSum?: number;
  graphType?: string;
  format?: FormatSettings;
  lineNames?: string[];
  rightAction?: JSX.Element[] | JSX.Element;
  colorSet?: string[];
  isMobile?: boolean;
  noContent?: boolean;
  selectByLegend?: boolean;
  showTitle?: boolean;
  onSelectChanged?: (selected: SelectedLines, name: string) => void;
  isLoading?: boolean;
};

const GetSerialData = (data: GraphData[], titleSet: string[], colorSet: string[]) => {
  const serialData: LineSeriesOption[] = [];

  for (let i = 0; i < data.length; i++) {
    serialData.push({
      name: titleSet === undefined ? "" : titleSet[i],
      data: data[i].filter(d => d[0] < new Date()).map(i => [i[0], i[1], i[2]]),
      type: "line",
      smooth: true,
      showSymbol: false,
      lineStyle: { width: 2 },
      color: [colorSet[i % colorSet.length]],
    });
  }
  return serialData;
};

const GetGraphOptions = (
  graphData: GraphData[],
  serialData: LineSeriesOption[],
  totalSum: number,
  graphType?: string,
  isMobile?: boolean,
  noContent?: boolean,
  selectByLegend?: boolean,
  showTitle?: boolean,
  legendId?: string,
  legendStartIndex?: number,
) => {
  const type = graphType === undefined ? "" : graphType === "%" ? graphType : " " + graphType;

  const defaultStyle = {
    fontFamily: "EB Garamond",
    fontSize: 64,
    color: colors.gray[900],
    fontWeight: 400,
  };

  const selectedLegends: Dictionary<boolean> = {};
  for (let index = 0; index < serialData.length; index++) {
    if (serialData[index].name === undefined) {
      break;
    }
    selectedLegends[serialData[index].name!] = graphData[index].length > 0;
  }

  const legend: LegendComponentOption | LegendComponentOption[] | undefined = selectByLegend
    ? {
        id: legendId,
        type: "scroll",
        orient: isMobile ? "horizontal" : "vertical",
        data: serialData.map(d => ({
          name: d.name?.toString(),
          textStyle: {
            width: 132,
            lineHeight: 18,
            overflow: "truncate",
          },
        })),
        selected: selectedLegends,
        selectedMode: true,
        scrollDataIndex: legendStartIndex ?? 0,
        top: 4,
        bottom: 10,
        height: 240,
        left: isMobile ? "center" : undefined,
        right: isMobile ? undefined : 10,
        width: "auto",
        pageIconColor: colors.brand[600],
        pageIconInactiveColor: colors.gray[400],
        animationDurationUpdate: 0,
      }
    : undefined;

  let totalSumValue = "";
  if (!noContent) {
    totalSumValue = totalSum.toString();
  }

  const options: EChartsOption = {
    title: showTitle
      ? {
          text: `{value|${totalSumValue}}{type|${type}}`,
          textStyle: {
            rich: {
              value: defaultStyle,
              type: {
                ...defaultStyle,
                ...(graphType === "%"
                  ? {}
                  : {
                      fontFamily: "Inter",
                      fontSize: 16,
                      baseline: "bottom",
                      padding: [0, 0, 12, 4],
                    }),
              },
            },
          },
          left: isMobile ? 18 : 10,
          top: 16,
        }
      : {},
    grid: {
      top: showTitle ? "112px" : isMobile ? "24px" : "16px", //"12px", // 16px + half text height
      left: isMobile ? "24px" : "16px",
      right: isMobile ? "24px" : selectByLegend ? "180px" : "16px",
      bottom: "50px",
      containLabel: true,
    },
    xAxis: {
      type: "time",
      axisTick: { show: false },
      axisLine: { lineStyle: { color: colors.gray[400] } },
      axisLabel: {
        color: colors.gray[400],
        align: "center",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "12px",
        formatter: "{dd} {MMM}",
        hideOverlap: true,
      },
      minInterval: 3600 * 1000 * 24 * 14,
      maxInterval: 3600 * 1000 * 24 * 30,
    },
    yAxis: {
      type: "value",
      min: function (value) {
        return Math.max(Math.min(value.min, 0), -100);
      },
      max: function (value) {
        return graphType === "%" ? Math.min(Math.floor(value.max) + 5, 100) : value.max;
      },
      splitNumber: 1.5,
      splitLine: {
        lineStyle: { color: colors.gray[400] },
      },
      axisLabel: {
        fontFamily: "Inter",
        fontWeight: 300,
        fontSize: "12px",
        color: colors.gray[400],
        formatter: "{value}" + type,
      },
      position: "left",
    },
    series: [...serialData],
    backgroundColor: noContent ? colors.gray[100] : colors.white,
    useUTC: true,
    tooltip: {
      trigger: "axis",
      confine: true,
      ...TooltipStyling,
      formatter(params: any) {
        const chartdate = format(params[0].value[0], "do $ MMMM, yyyy").replace("$", "of");
        const values = params
          .map(
            (p: { marker: string; seriesName: string; value: string[] }) =>
              '<li style="list-style:none">' + p.marker + p.seriesName + "&nbsp;&nbsp;" + p.value[1] + type + "</li>",
          )
          .join(" ");
        return `${chartdate}` + `${values}`;
      },
    },
    legend: legend,
    animationDuration: 600,
  };

  return options;
};

export default function LineChartReport({ data, lineNames: seasonNames, ...props }: LineChartReportProps) {
  const titleSet = seasonNames ?? ["No Season"];
  const colorSet = props.colorSet ?? ["bg-brand-800"];
  const legendId = "legendId";
  const legendItem = useRef(0);

  //If you want to add an export PDF button to the Stock Hitstory graph that actually uses these events,
  //you need to be aware that these events are currently causing the line to not show up in the exported PDF.
  const onEvents: Record<string, Function> = props.selectByLegend
    ? {
        legendselectchanged: function (params: any) {
          if (props.onSelectChanged !== undefined) {
            props.onSelectChanged(params.selected, params.name);
          }
        },
        legendScroll: function (params: any) {
          legendItem.current = params.scrollDataIndex;
        },
      }
    : {};

  const graphOptions = useMemo(() => {
    const serialData: LineSeriesOption[] = GetSerialData(data, titleSet, colorSet);
    const options: EChartsOption = GetGraphOptions(
      data,
      serialData,
      props.totalSum ?? 0,
      props.graphType,
      props.isMobile,
      props.noContent,
      props.selectByLegend,
      props.showTitle,
      legendId,
      legendItem.current,
    );
    return options;
  }, [data]);

  return (
    <>
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        echarts={echarts}
        className="h-fit"
        option={graphOptions}
        notMerge={true}
        onEvents={onEvents}
      />
      {props.isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10">
          <Loader />
        </div>
      )}
      {props.rightAction && <span className="m-4 mobile:mx-6 absolute left-0 bottom-0">{props.rightAction}</span>}
    </>
  );
}
