export default function LinkBroken() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1H5.5V3H6.5V1ZM1.79193 2.50098L2.49891 1.79375L4.20688 3.50111L3.49989 4.20834L1.79193 2.50098ZM11.7907 12.4961L12.4979 11.7891L14.2053 13.4971L13.4981 14.2041L11.7907 12.4961ZM1 5.5H3V6.5H1V5.5ZM15 9.5H13V10.5H15V9.5ZM9.5 13H10.5V15H9.5V13ZM8.28999 10.535L6.43499 12.395C6.24917 12.5808 6.02856 12.7282 5.78578 12.8288C5.54299 12.9294 5.28278 12.9811 5.01999 12.9811C4.7572 12.9811 4.49698 12.9294 4.25419 12.8288C4.01141 12.7282 3.79081 12.5808 3.60499 12.395C3.2297 12.0197 3.01887 11.5107 3.01887 10.98C3.01887 10.4493 3.2297 9.94028 3.60499 9.565L5.46499 7.705L4.75499 7L2.89999 8.86C2.61532 9.13707 2.38851 9.46792 2.23273 9.83334C2.07695 10.1988 1.9953 10.5915 1.99251 10.9887C1.98971 11.386 2.06584 11.7798 2.21648 12.1474C2.36711 12.515 2.58924 12.849 2.86999 13.13C3.15031 13.408 3.48275 13.628 3.84827 13.7773C4.21378 13.9266 4.60517 14.0023 4.99999 14C5.40167 14.0004 5.79943 13.921 6.17021 13.7665C6.54099 13.612 6.87742 13.3855 7.15999 13.1L8.99999 11.245L8.28999 10.535ZM9.565 3.60505L7.705 5.46505L7 4.75505L8.84 2.90005C9.12257 2.61456 9.45899 2.388 9.82977 2.23351C10.2006 2.07902 10.5983 1.99966 11 2.00005C11.3948 1.99777 11.7862 2.07344 12.1517 2.22274C12.5172 2.37203 12.8497 2.59201 13.13 2.87005C13.4107 3.15109 13.6329 3.4851 13.7835 3.85267C13.9341 4.22025 14.0103 4.61409 14.0075 5.01132C14.0047 5.40856 13.923 5.80128 13.7673 6.16671C13.6115 6.53213 13.3847 6.86298 13.1 7.14005L11.245 9.00005L10.535 8.29505L12.395 6.43505C12.5808 6.24923 12.7282 6.02863 12.8288 5.78584C12.9294 5.54306 12.9811 5.28284 12.9811 5.02005C12.9811 4.75726 12.9294 4.49704 12.8288 4.25426C12.7282 4.01147 12.5808 3.79087 12.395 3.60505C12.2092 3.41923 11.9886 3.27183 11.7458 3.17126C11.503 3.0707 11.2428 3.01894 10.98 3.01894C10.7172 3.01894 10.457 3.0707 10.2142 3.17126C9.97142 3.27183 9.75082 3.41923 9.565 3.60505Z"
        fill="currentcolor"
      />
    </svg>
  );
}
