import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TFunction } from "i18next";
import React, { ErrorInfo } from "react";
import { Trans, withTranslation } from "react-i18next";
import { PrimaryButton } from "./buttons/PrimaryButton";

class PageErrorBoundary extends React.Component<
  { children?: React.ReactNode; t: TFunction<"translation"> },
  { error?: Error; errorInfo?: ErrorInfo; newApp?: boolean }
> {
  constructor(props: { children?: React.ReactNode; t: TFunction<"translation"> }) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.error && /ChunkLoadError/.test(this.state.error.name)) {
      return (
        <div className="h-[calc(100vh-10rem)] flex flex-col justify-around items-center">
          <div className="flex flex-col content-center items-center">
            <FontAwesomeIcon className="block w-16 h-16 text-brand" icon="arrow-circle-up" />
            <p className="text-center font-light mt-4 mb-4">{this.props.t("ui.updateAvailable")}</p>
            <PrimaryButton onClick={() => window.location.reload()} label={this.props.t("ui.refresh")} />
          </div>
        </div>
      );
    }
    if (this.state.errorInfo) {
      return (
        <div className="h-[calc(100vh-10rem)] flex flex-col justify-around items-center">
          <div className="flex flex-col content-center items-center">
            <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={faTriangleExclamation} />
            <p className="text-center font-light mt-4">
              <Trans i18nKey="ui.pageException" />
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const TranslatedPageBoundary = withTranslation()(PageErrorBoundary);
