import { parse } from "date-fns/parse";
import { DownloadArticleLocationState } from "src/pages/inventory/ArticleDownload";
import {
  AddedNewUserMeta,
  DeclinedWithdrawRetailerMeta,
  DeclinedWithdrawSupplierMeta,
  InvoiceAgreementCreatedMeta,
  LowStockMeta,
  NotificationMeta,
  NotificationType,
  PaymentCompletedMeta,
  RemovedUserMeta,
  RetryWithdrawalRetailerMeta,
  RetryWithdrawalSupplierMeta,
  UploadedArticlesMeta,
} from "../../api/NotificationApi";
import Paths from "../../configuration/Paths";
import { AgreementLocationState } from "../../pages/new/Agreements";
import { PaymentLocationState } from "../../pages/new/Payment";
import { WithdrawLocationState } from "../../pages/new/Withdrawals";
import { IndividualStockLocationState } from "../../pages/inventory/IndividualStocks";

type Config = {
  [key in NotificationType]: (m: NotificationMeta) => NotificationSpec;
};

const config: Config = {
  LowStock: (m: NotificationMeta) => {
    const meta = m as LowStockMeta;
    const state: IndividualStockLocationState = {
      search: meta.StyleNr,
    };

    return {
      path: Paths.productTracker.individualStocks,
      state,
      message: `Low stock, ${meta.DaysLeft} days left for ${meta.StyleNr}`,
    };
  },
  PaymentCompleted: (m: NotificationMeta) => {
    const meta = m as PaymentCompletedMeta;
    const state: PaymentLocationState = {
      date: parse(meta.Date, "yyyy-MM-dd", new Date()),
      retailerId: meta.RetailerId,
    };

    return {
      path: Paths.payment.payoutReport,
      state,
      message: `Received payment of ${meta.AmountMinor / 100} ${meta.Currency}`,
    };
  },
  InvoiceAgreementCreated: (m: NotificationMeta) => {
    const meta = m as InvoiceAgreementCreatedMeta;
    const state: AgreementLocationState = {
      search: meta.AgreementId,
    };

    return {
      path: Paths.agreements.partners,
      state: state,
      message: `Invoice ${meta.InvoiceNo} filed by ${meta.RetailerName}`,
    };
  },
  DeclinedWithdrawRetailer: (m: NotificationMeta) => {
    const meta = m as DeclinedWithdrawRetailerMeta;
    const state: WithdrawLocationState = {
      isRetailerNotification: true,
      date: parse(meta.Date, "yyyy-MM-dd", new Date()),
      retailerId: meta.SupplierId,
    };

    return {
      path: Paths.withdrawals.withdrawalReport,
      state: state,
      message: `Declined withdrawal of ${meta.AmountMinor / 100} ${meta.Currency}`,
    };
  },
  DeclinedWithdrawSupplier: (m: NotificationMeta) => {
    const meta = m as DeclinedWithdrawSupplierMeta;
    const message = `Declined withdrawal of ${meta.AmountMinor / 100} ${meta.Currency} for ${meta.RetailerName}`;
    return {
      message: message,
      noLink: true,
    };
  },
  RetryWithdrawalSupplier: (m: NotificationMeta) => {
    const meta = m as RetryWithdrawalSupplierMeta;
    const message = `Retried withdrawal of ${meta.AmountMinor / 100} ${meta.Currency} from ${meta.RetailerName}`;
    return {
      message: message,
      noLink: true,
    };
  },
  RetryWithdrawalRetailer: (m: NotificationMeta) => {
    const meta = m as RetryWithdrawalRetailerMeta;
    const state: WithdrawLocationState = {
      isRetailerNotification: true,
      date: parse(meta.Date, "yyyy-MM-dd", new Date()),
    };

    return {
      path: Paths.withdrawals.withdrawalReport,
      state: state,
      message: `Retried withdrawal of ${(meta.AmountMinor + meta.RetailerFee) / 100} ${meta.Currency}`,
    };
  },
  AddedNewUser: (m: NotificationMeta) => {
    const meta = m as AddedNewUserMeta;
    const message = `${meta.Name} has been added as ${meta.Role} to ${meta.OrgName}`;
    return {
      message: message,
      noLink: true,
    };
  },
  RemovedUser: (m: NotificationMeta) => {
    const meta = m as RemovedUserMeta;
    const message = `${meta.Name} has been removed as ${meta.Role} from ${meta.OrgName}`;
    return {
      message: message,
      noLink: true,
    };
  },
  UploadedArticles: (m: NotificationMeta) => {
    const meta = m as UploadedArticlesMeta;

    const state: DownloadArticleLocationState = {
      supplierId: meta.SupplierId,
      brand: meta.Brand,
      seasonId: meta.SeasonId,
    };

    const message = `${meta.SupplierName} uploaded ${meta.ArticleCount} articles`;
    return {
      path: Paths.articles.download,
      state,
      message: message,
    };
  },
};

export interface NotificationSpec {
  path?: string;
  state?: any;
  noLink?: boolean;
  message: string;
}

export function getNotificationSpec(type: NotificationType, meta: NotificationMeta): NotificationSpec {
  return config[type](meta);
}
