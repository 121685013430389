export default function Pin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height="16"
      width="16"
      id="Pin-1--Streamline-Sharp.svg"
    >
      <g id="Pin-1--Streamline-Sharp.svg">
        <path
          id="Vector 48"
          stroke="currentcolor"
          d="m17 12 4.5 -2.5 -7 -7L12 7l-8.5 3.5 10 10L17 12Z"
          strokeWidth="1.5"
        ></path>
        <path id="Vector 118" stroke="currentcolor" d="m1.5 22.5 7 -7" strokeWidth="1.5"></path>
      </g>
    </svg>
  );
}

export function PinFilled() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 9L15 1L12 6L2.99997 10L7.79297 14.793L1.29297 21.293L2.70697 22.707L9.20697 16.207L14 21L18 12L23 9Z"
        fill="currentcolor"
      />
    </svg>
  );
}
