export default function SearchlocateMirror() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1H15V2H7V1ZM15 3.5H7V4.5H15V3.5ZM8.15 11.95L10.5 14.3L9.8 15L7.45 12.65C6.75 13.2 5.9 13.5 5 13.5C2.8 13.5 1 11.7 1 9.5C1 7.3 2.8 5.5 5 5.5C7.2 5.5 9 7.3 9 9.5C9 10.4 8.7 11.25 8.15 11.95ZM2 9.5C2 11.15 3.35 12.5 5 12.5C6.65 12.5 8 11.15 8 9.5C8 7.85 6.65 6.5 5 6.5C3.35 6.5 2 7.85 2 9.5ZM11 6H15V7H11V6Z"
        fill="currentcolor"
      />
    </svg>
  );
}
