import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkButton } from "@impulso/common/components/link";
import LinkBroken from "@impulso/common/Icons/LinkBroken";
import Pin, { PinFilled } from "@impulso/common/Icons/Pin";
import { Loader } from "@mantine/core";
import { LegacyRef, PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";
import BarChartReport from "../components/reports/BarChartReport";
import LineChartReport from "../components/reports/lineChartReport";

export interface Pin {
  togglePinned: (item: string) => void;
  isPinned: (item: string) => boolean;
  visible?: boolean;
}

export const alwaysPinnedPin: Pin = {
  togglePinned: () => {},
  isPinned: () => true,
  visible: false,
};

export type ReportProps = {
  containerRef?: LegacyRef<HTMLDivElement>;
  title: string;
  subtitle?: string;
  dateSpan?: string;
  backgroundColor?: string;
  height?: string;
  isMobile?: boolean;
  id?: string;
  pin?: Pin;
  customStyling?: string;
};

const createGraphData = () => {
  const graphData = [];
  for (let i = 0; i < 4; i++) {
    graphData.push({ label: "", value: (i + 1) * 10 });
  }
  return graphData;
};

const createLineGraphData = () => {
  const graphData: [Date, number][][] = [];
  const points: [Date, number][] = [];
  const height = 100;

  for (let i = 0; i < height; i++) {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - height);
    currentDate.setDate(currentDate.getDate() + i);
    const currentPoint: [Date, number] = [currentDate, 0];
    points.push(currentPoint);
  }
  graphData.push(points);
  return graphData;
};

export function Report(props: PropsWithChildren<ReportProps>) {
  const pinned = props.pin?.isPinned(props.id ?? "") ?? false;
  return (
    <ReportContainer
      id={props.id}
      order={pinned ? "order-1" : "order-2"}
      containerRef={props.containerRef}
      backgroundColor={props.backgroundColor}
      isMobile={props.isMobile}
      height={props.height}
      noBorder={props.title == ""}
      customStyling={props.customStyling}
    >
      <ReportHeader
        title={props.title}
        subtitle={props.subtitle}
        dateSpan={props.dateSpan}
        pin={props.pin}
        id={props.id ?? ""}
      />
      <div className={`flex flex-col h-full overflow-hidden`}>{props.children}</div>
    </ReportContainer>
  );
}

export function ReportLoading(props: { height?: string }) {
  return (
    <ReportContainer order="order-2" height={props.height}>
      <div className="w-full h-full col-span-6 flex items-center justify-center">
        <Loader />
      </div>
    </ReportContainer>
  );
}

export function ReportError(props: { height?: string; isMobile?: boolean }) {
  return (
    <ReportContainer isMobile={props.isMobile} height={props.height} order="order-4">
      <div className="w-full, h-full col-span-6 flex items-center justify-center">
        <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={faTriangleExclamation} />
      </div>
    </ReportContainer>
  );
}

export function ReportNoContent(props: { title: string; noContentMsg: string; isMobile?: boolean }) {
  const [graph] = useState<{ label: string; value: number }[]>(createGraphData());
  return (
    <ReportContainer isMobile={props.isMobile} order="order-3">
      <div className="absolute inset-0 flex -z-10 justify-around bg-gray-100 items-center pointer-events-none">
        <div className="w-full h-full">
          <BarChartReport data={graph} dateSpan={""} title="" isMobile={props.isMobile} />
        </div>
      </div>
      <span className="text-base font-semibold p-4 pb-0 text-gray-600">{props.title}</span>
      <span className="text-base flex flex-row gap-2 items-center pl-4 text-gray-600">
        <LinkBroken />
        {props.noContentMsg}
      </span>
    </ReportContainer>
  );
}

export function LineChartNoContent(props: {
  title: string;
  noContentMsg: string;
  showLink?: boolean;
  isMobile?: boolean;
}) {
  const navigate = useNavigate();

  const [graph] = useState<[Date, number][][]>(createLineGraphData());
  return (
    <ReportContainer isMobile={props.isMobile} order="order-3">
      <div className="absolute inset-0 -z-10 flex justify-around bg-ray-100 items-center pointer-events-none">
        <div className="w-full h-full">
          <LineChartReport data={graph} isMobile={props.isMobile} noContent={true} showTitle />
        </div>
      </div>
      <span className="text-base font-semibold p-4 pb-0 text-gray-600">{props.title}</span>
      <span className="text-base flex flex-row gap-2 items-center pl-4 text-gray-600">
        <LinkBroken />
        {props.noContentMsg}
      </span>
      {props.showLink && (
        <span className="text-base flex flex-row gap-1 items-center pl-10 text-gray-600">
          <LinkButton
            label={"click here"}
            onClick={() => {
              navigate("product-tracker/edit-deliveries");
            }}
          />{" "}
          <p>to edit stock balance</p>
        </span>
      )}
    </ReportContainer>
  );
}

function ReportContainer(
  props: PropsWithChildren<{
    containerRef?: LegacyRef<HTMLDivElement>;
    backgroundColor?: string;
    isMobile?: boolean;
    height?: string;
    noBorder?: boolean;
    id?: string;
    order: string;
    customStyling?: string;
  }>,
) {
  return (
    <div
      id={props.id}
      style={{ backgroundColor: props.backgroundColor }}
      className={
        `${props.height ?? "h-[446px]"} flex group flex-col relative border-gray-300 col-span-6 ` +
        (props.order ? props.order : "order-1") +
        " " +
        (props.customStyling ? props.customStyling : "") +
        " " +
        (props.noBorder ? "" : props.isMobile ? " border-t" : " border")
      }
      ref={props.containerRef}
    >
      {props.children}
    </div>
  );
}

export type ReportHeaderProps = {
  title?: string;
  subtitle?: string;
  id: string;
  dateSpan?: string;
  pin?: Pin;
};

export function ReportHeader(props: ReportHeaderProps) {
  if (!props.title) {
    return null;
  }

  const pinned = props.pin?.isPinned(props.id) ?? false;

  const pinVisible = props.pin != undefined && (props.pin?.visible ?? true);
  return (
    <div className="p-4 mobile:px-6 pb-0">
      <div className="flex justify-between items-start relative">
        <p className="font-sans font-semibold text-base text-black ">
          {props.title}
          <span className="text-gray-600 font-normal ml-2">{props.subtitle}</span>
        </p>
        <div className="flex gap-4">
          <p className="font-sans font-regular text-xs text-gray-600">{props.dateSpan}</p>
          <div
            className={!pinVisible ? "hidden" : pinned ? "" : "group-hover:block hidden h-tablet:!block"}
            onClick={() => props.pin && props.pin.togglePinned(props.id)}
          >
            {pinned ? <PinFilled /> : <Pin />}
          </div>
        </div>
      </div>
    </div>
  );
}
