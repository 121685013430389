import ArrowDown from "@impulso/common/Icons/ArrowDown";
import ArrowUp from "@impulso/common/Icons/ArrowUp";
import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon";
import Sortable from "@impulso/common/Icons/Sortable";
import { useVTabletSizeDetect } from "@impulso/common/hooks/useMobileDetect";
import { Accordion, Loader } from "@mantine/core";
import React, { isValidElement, useState } from "react";

export interface TableReportColumn<T> {
  id?: string;
  accessor: keyof T & string;
  title: string | JSX.Element;
  visibility?: "hidden" | "visible" | "alwaysVisible";
  sortable: boolean;
  textAlignment?: "right" | "left" | "center";
  render?: (item: T) => string | JSX.Element;
  definedWidth?: string;
}

export type DataTableProps<T> = {
  className?: string;
  records: any[] | undefined;
  columns: TableReportColumn<T>[];
  ignoreBodyVisibility?: boolean;
  sortStatus: DataTableSortStatus;
  onSortStatusChange: (status: DataTableSortStatus) => void;
  noContentMessage?: string;
  rowHeight?: string;
  onClick?: (item: T) => void;
  rightIcon?: JSX.Element;
  noPanel?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  rowBody?: (item: T) => JSX.Element;
};

export type DataTableSortStatus = {
  columnAccessor: string;
  direction: "asc" | "desc";
};

export default function DataTable<T>(props: DataTableProps<T>) {
  const showOptions = props.columns.some(c => c.visibility != "alwaysVisible");
  return (
    <div className={"max-w-full relative shrink flex min-h-[250px] gap-0 overflow-x-none " + props.className}>
      {(!props.records || props.records?.length) === 0 && (
        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {props.noContentMessage}
        </p>
      )}
      <Column
        keyVal={"pinned"}
        column={props.columns[0]}
        definedWidth="max-w-[250px] v-tablet:max-w-[150px] min-w-[120px] z-10 bg-white grow"
        fixed
        rowData={props.records}
        rowHeight={props.rowHeight}
        sortMode={props.sortStatus}
        setSortMode={props.onSortStatusChange}
      />
      <div className="flex relative grow">
        <div className="flex overflow-x-auto no-scrollbar shrink absolute right-0 top-0 bottom-0 w-full">
          {props.columns.slice(1, showOptions ? -1 : undefined).map((column, i) => (
            <Column
              key={column.accessor}
              definedWidth={
                (column.definedWidth == undefined ? "" : column.definedWidth) +
                (i === props.columns.length - 3 ? " pr-[32px]" : "")
              }
              keyVal={column.accessor}
              column={column}
              rowData={props.records}
              rowHeight={props.rowHeight}
              sortMode={props.sortStatus}
              setSortMode={props.onSortStatusChange}
            />
          ))}
        </div>
      </div>
      {showOptions && (
        <div
          key={"options"}
          className="absolute z-10 bg-gradient-to-r from-transparent to-white right-0 top-0 bottom-0"
        >
          <div className="hover:bg-gray-200 border-b bg-white border-l p-2 py-1">
            {props.columns[props.columns.length - 1].title}
          </div>
        </div>
      )}
    </div>
  );
}

type ColumnProps<T> = {
  column: TableReportColumn<T>;
  sortMode: DataTableSortStatus;
  setSortMode: (status: DataTableSortStatus) => void;
  definedWidth?: string;
  fixed?: boolean;
  rowData: T[] | undefined;
  keyVal?: string;
  rowHeight?: string;
};

function Column<T>(props: ColumnProps<T>) {
  const textAlign = getTextAlignment(props.column.textAlignment);
  const rows = props.rowData?.map(r => {
    if (props.column.render) {
      const obj = props.column.render(r);
      if (isValidElement(obj)) {
        return obj;
      } else {
        return <p className={"w-full truncate " + textAlign}>{obj}</p>;
      }
    } else {
      const accessor = r[props.column.accessor] as any;
      return <p className={"w-full truncate " + textAlign}>{accessor}</p>;
    }
  });
  return (
    <div
      key={props.keyVal}
      className={
        "flex flex-col h-min grow " + (props.fixed ? "border-r " : "") + (props.definedWidth ? props.definedWidth : "")
      }
    >
      <ColumnHeaderItem {...props} />
      {rows?.map((r, i) => {
        return (
          <div
            key={props.column.accessor + " col - " + i}
            className={" flex items-center border-b px-2 pr-4 text-M [&_div]:mx-0 " + (props.rowHeight ?? " h-[50px]")}
          >
            {r}
          </div>
        );
      })}
    </div>
  );
}

export function FoldoutDataTable<T>(props: DataTableProps<T>) {
  const [openRowId, setOpenRowId] = useState<string | null>(null);
  const { isVTabletSize, initialized } = useVTabletSizeDetect();
  const columns = props.columns.filter(c =>
    isVTabletSize && initialized ? c.visibility == "alwaysVisible" : c.visibility != "hidden",
  );
  const hiddenColumns = props.columns.filter(c =>
    isVTabletSize && initialized && !props.ignoreBodyVisibility
      ? c.visibility != "alwaysVisible"
      : c.visibility == "hidden",
  );

  if (props.isLoading) {
    return (
      <div className="mt-16">
        <Loader className="mx-auto " />
        <p className="text-center mt-6 ml-2">{props.loadingText}</p>
      </div>
    );
  }

  return (
    <div className={"max-w-full relative shrink flex min-h-[250px] flex-col gap-0 overflow-x-none " + props.className}>
      {(!props.records || props.records?.length) === 0 && (
        <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {props.noContentMessage}
        </p>
      )}
      <div className="flex shrink relative w-full h-[42px] mobile:pl-2">
        {columns.map((column, i) => (
          <ColumnHeaderItem
            key={column.accessor}
            definedWidth={column.definedWidth}
            keyVal={column.accessor}
            column={column}
            rowData={props.records}
            rowHeight={props.rowHeight}
            sortMode={props.sortStatus}
            setSortMode={props.onSortStatusChange}
          />
        ))}
        <div className="min-w-[48px] border-b v-tablet:hidden"></div>
      </div>
      <Accordion
        onChange={value => setOpenRowId(value)}
        className="grow"
        chevron={props.noPanel ? <></> : (props.rightIcon ?? <DownChevronIcon />)}
        styles={{ content: { padding: 0 } }}
      >
        {props.records?.map((r, i) => (
          <FoldoutRowItem
            openRowId={openRowId}
            onClick={props.onClick}
            rowBody={props.rowBody!}
            columns={columns}
            hiddenColumns={hiddenColumns}
            rowData={r}
            keyVal={i.toString()}
            key={"p-" + r.id}
            noPanel={props.noPanel}
          />
        ))}
      </Accordion>
    </div>
  );
}

type RowProps<T> = {
  columns: TableReportColumn<T>[];
  hiddenColumns: TableReportColumn<T>[];
  rowData: any | undefined;
  keyVal?: string;
  onClick?: (item: T) => void;
  noPanel?: boolean;
  rowBody: (item: T) => JSX.Element;
  openRowId: string | null;
};

function FoldoutRowItem<T>(props: RowProps<T>) {
  const rows = props.columns.map(column => {
    const textAlign = getTextAlignment(column.textAlignment);
    const className = "w-full truncate " + textAlign + " " + (column.definedWidth ?? "");
    if (column.render) {
      const obj = column.render(props.rowData);
      if (isValidElement(obj)) {
        return obj;
      } else {
        return (
          <p className={className} key={column.accessor}>
            {obj}
          </p>
        );
      }
    } else {
      return (
        <p className={className} key={column.accessor}>
          {props.rowData[column.accessor]}
        </p>
      );
    }
  });

  const hiddenRows = props.hiddenColumns.map((column, i) => {
    const wrapper = (column: TableReportColumn<T>, obj: JSX.Element | JSX.Element[] | never[]) => {
      return (
        <div key={column.accessor} className="flex odd:border-r odd:pr-4 even:ml-4 flex-col gap-2">
          <p className="whitespace-nowrap text-sc text-gray-600">{column.title.toString().toUpperCase()}</p>
          {obj}
        </div>
      );
    };
    const textAlign = getTextAlignment(column.textAlignment);
    if (column.render) {
      const obj = column.render(props.rowData);
      if (isValidElement(obj)) {
        return wrapper(column, obj);
      } else {
        return wrapper(column, <p className={"w-full truncate " + textAlign}>{obj}</p>);
      }
    } else return wrapper(column, <p className={"w-full truncate " + textAlign}>{props.rowData[column.accessor]}</p>);
  });

  return (
    <Accordion.Item
      onClick={value => props.onClick && props.onClick(props.rowData)}
      key={props.rowData["id"]}
      value={props.rowData["id"]}
    >
      <Accordion.Control className="relative h-[48px]">
        <div className="absolute inset-2 mobile:left-2 left-0 right-0 flex items-center text-M grow [&>*]:p-2">
          {rows}
          <div className="min-w-[48px] v-tablet:hidden"></div>
        </div>
      </Accordion.Control>
      {props.noPanel ? (
        <></>
      ) : (
        <Accordion.Panel>
          <div className="px-2 mobile:px-4">
            <div className={props.hiddenColumns.length > 0 ? "border-b py-4 gap-y-4 grid grid-cols-2" : ""}>
              {hiddenRows.map((r, i) => {
                return r;
              })}
            </div>
            {props.openRowId == props.rowData["id"] && props.rowBody(props.rowData)}
          </div>
        </Accordion.Panel>
      )}
    </Accordion.Item>
  );
}

function ColumnHeaderItem<T>(props: ColumnProps<T>) {
  const textAlign =
    props.column.textAlignment == "center"
      ? "text-center"
      : props.column.textAlignment == "right"
        ? "text-right"
        : "text-left";
  const sortState = props.sortMode.columnAccessor !== props.column.accessor ? "none" : props.sortMode.direction;
  const id = props.column.title.toString() + (props.column.id ?? "");

  const sortClick = () => {
    if (!props.column.sortable) {
      return;
    }
    props.setSortMode({
      columnAccessor: props.column.accessor,
      direction: sortState !== "desc" ? "desc" : "asc",
    });
  };

  return (
    <div
      id={id}
      className={
        "flex items-center gap-2 text-S font-semibold border-b p-2 bg-white hover:bg-gray-200 select-none hover:cursor-pointer " +
        (props.definedWidth ?? "w-full")
      }
      onClick={sortClick}
    >
      <div className={"truncate " + textAlign + (textAlign === "text-right" ? " w-full " : "")}>
        {props.column.title}
      </div>
      {props.column.sortable && (
        <div className="h-4 aspect-square mr-2 text-gray-900">
          {sortState === "none" ? <Sortable /> : sortState === "asc" ? <ArrowDown /> : <ArrowUp />}
        </div>
      )}
    </div>
  );
}

function getTextAlignment(textAlignment: "right" | "left" | "center" | undefined) {
  return textAlignment == "center" ? "text-center" : textAlignment == "right" ? "text-right" : "text-left";
}
