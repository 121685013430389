import type { EChartsOption } from "echarts";
import * as echarts from "echarts/core";

import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent, TitleComponent, DatasetComponent } from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import { colors } from "@impulso/common/Theme";
import { Language } from "@impulso/common/Language";
import { OptionDataItemObject, OptionDataValue } from "echarts/types/src/util/types";
import { BarData, ChartReport } from "./ChartReport";
import { TooltipStyling } from "./ReportStyling";
import { Pin } from "src/common/reports/Report";

echarts.use([BarChart, CanvasRenderer, GridComponent, TooltipComponent, TitleComponent, DatasetComponent]);

export interface MoneyFormat {
  type: "money";
  currency: string;

  /** Default true */
  compressThousands?: boolean;
}

export interface PostfixFormat {
  type: "postfix";
  postfix: string;
}

export type FormatSettings = MoneyFormat | PostfixFormat;

export type BarChartReportProps<T extends BarData> = {
  title: string;
  data: T[];
  dateSpan: string;
  format?: FormatSettings;
  isMobile?: boolean;
  rightAction?: JSX.Element;
  onClick?(data: T): void;
  pin?: Pin;
  onViewMoreClicked?: () => void;
  customStyling?: string;
};

export default function BarChartReport<T extends BarData>(props: BarChartReportProps<T>) {
  const noContent = props.title === "";
  let data = props.data.slice();
  if (!noContent) {
    data.sort((a, b) => b.value - a.value);
  }
  data = data.slice(0, props.isMobile ? 5 : 10); //Limit amount of bars in mobile view, consider changing to x-scrolling wide graph in the future.

  const options: EChartsOption = {
    animation: true,
    title: [
      {
        text: props.format?.type === "money" ? props.format.currency + "(ex VAT)" : "",
        textStyle: { fontFamily: "Inter", fontSize: 16, color: colors.gray[600], fontWeight: 400 },
        left: props.isMobile ? 18 : 10,
      },
    ],
    grid: {
      top: noContent ? "102px" : "64px", // 16px + half text height
      left: props.isMobile ? "24px" : "16px",
      right: props.isMobile ? "24px" : "16px",
      bottom: noContent ? "32px" : "64px",
      containLabel: noContent ? false : true,
    },
    xAxis: {
      type: "category",
      axisTick: { show: false },
      axisLine: { lineStyle: { color: colors.gray[400] } },
      axisLabel: {
        color: "black",
        fontFamily: "Inter",
        fontWeight: 400,
        interval: 0,
        width: 80,
        overflow: "truncate",
      },
      data: data.map(d => d.label),
    },
    yAxis: {
      splitNumber: 3,
      splitLine: {
        lineStyle: { color: colors.gray[400] },
      },
      position: "left",
      axisLabel: {
        formatter: labelFormatter(props.format),
        fontFamily: "Inter",
        fontWeight: 300,
      },
      tooltip: {},
    },
    series: {
      type: "bar",
      data: data as OptionDataItemObject<number>[],
      barMaxWidth: data.length > 5 ? 24 : 48,
      tooltip: { valueFormatter: tooltipFormatter(props.format) },
    },
    color: [props.title == "" ? colors.gray[300] : colors.brand[800]],
    tooltip: {
      trigger: "axis",
      ...TooltipStyling,
    },
  };

  return (
    <ChartReport
      onViewMoreClicked={props.onViewMoreClicked}
      options={options}
      title={props.title}
      dateSpan={props.dateSpan}
      isMobile={props.isMobile}
      exportDirection="landscape"
      onBarClick={props.onClick}
      rightAction={props.rightAction}
      customStyling={props.customStyling}
      pin={props.pin}
    />
  );
}

function tooltipFormatter(
  format?: FormatSettings,
): ((value: OptionDataValue | OptionDataValue[]) => string) | undefined {
  if (!format) {
    return undefined;
  }

  switch (format.type) {
    case "money":
      return value => Language.FormatMoney(value as number, format.currency);
    case "postfix":
      return value => (value as number).toString() + " " + format.postfix;
    default:
      return undefined;
  }
}

function labelFormatter(format?: FormatSettings): ((value: number) => string) | undefined {
  if (!format) {
    return _ => "";
  }

  switch (format.type) {
    case "money": {
      const compressThousands = format.compressThousands ?? true;
      if (compressThousands) {
        return value => Language.FormatMoney(value / 1000, "K", 0);
      } else {
        return value => Language.FormatMoney(value, "");
      }
    }
    case "postfix":
      return value => value.toString() + " " + format.postfix;
    default:
      return undefined;
  }
}
