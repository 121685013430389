export const TooltipStyling = {
  show: true,
  backgroundColor: "black",
  borderRadius: 0,
  borderWidth: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowBlur: 0,
  textStyle: { fontSize: 12, fontFamily: "Inter", fontWeight: 300, color: "white" },
};
